<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addAccessoriesModal' hide-footer>
        <addAccessories @closeAddAccessories='toggleAddAccessories()' @addAccessoriesSuccess='addAccessoriesSuccess()'></addAccessories>
      </b-modal>
      <b-modal ref='editAccessoriesModal' hide-footer>
        <editAccessories
          :editingAccessories='currentAccessories'
          @closeEditAccessories='toggleEditAccessories()'
          @editAccessoriesSuccess='editAccessoriesSuccess()'
        ></editAccessories>
      </b-modal>
      <div class='row'>
        <h2>Accessories</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddAccessories()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addAccessories from '../../components/addAccessories';
import editAccessories from '../../components/editAccessories';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'VechicleCategory',
                field: 'VechicleCategory',
                sort: 'asc',
              },
              {
                label: 'AccessoryCount',
                field: 'AccessoryCount',
                sort: 'asc',
              },
              {
                label: 'Model',
                field: 'Model',
                sort: 'asc',
              },
              {
                label: 'Type',
                field: 'Type',
                sort: 'asc',
              },
              {
                label: 'PartNumber',
                field: 'PartNumber',
                sort: 'asc',
              },
              {
                label: 'Description',
                field: 'Description',
                sort: 'asc',
              },
              {
                label: 'VehicleAcessoryName',
                field: 'VehicleAcessoryName',
                sort: 'asc',
              },
              {
                label: 'WebsiteDescription',
                field: 'WebsiteDescription',
                sort: 'asc',
              },
              {
                label: 'Price',
                field: 'Price',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addAccessoriesOpen: false,
      editAccessoriesOpen: false,
      currentAccessories: {}
    };
  },
  components: {
    addAccessories,
    editAccessories,
    Datatable,
  },
  created: function() {
    this.getAccessories();
    this.addAccessoriesOpen = false;
    this.editAccessoriesOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getAccessories() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getAccessories', payload);
    },
    editItem(Accessories) {
      this.toggleEditAccessories();
      this.currentAccessories = Accessories;
    },
    toggleAddAccessories() {
      if(this.addAccessoriesOpen)
      {
        this.$refs.addAccessoriesModal.hide()
      }
      else{
        this.$refs.addAccessoriesModal.show()
      }
      this.addAccessoriesOpen = !this.addAccessoriesOpen;
    },
    addAccessoriesSuccess() {
      this.toggleAddAccessories();
      miniToastr['success']('Accessories Added', 'Success', 1000, null);
      this.getAccessories();
    },
    toggleEditAccessories() {
      if(this.editAccessoriesOpen)
      {
        this.$refs.editAccessoriesModal.hide()
      }
      else{
        this.$refs.editAccessoriesModal.show()
      }
      this.editAccessoriesOpen = !this.editAccessoriesOpen;
    },
    editAccessoriesSuccess() {
      this.toggleEditAccessories();
      miniToastr['success']('Accessories Edited', 'Success', 1000, null);
      this.getAccessories();
    }
  }
};
</script>
<style scoped>
.AccessoriesThumbnail {
  height: 50px;
}
</style>
